import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import { RydePassWebErrorType } from '../../../utils/Errors'
import { Button } from '../../elements/Button'
import { Link } from '../../elements/Link'
import { Title } from '../../elements/Title'
import { ContainerStyle } from '../../organisms/Container'

type ErrorMessage = {
  title: string
  description: string
}

type Props = {
  type?: RydePassWebErrorType
}

const getMessage = (type: RydePassWebErrorType): ErrorMessage => {
  switch (type) {
    case RydePassWebErrorType.NotFound:
      return {
        title: 'お探しのページが見つかりません',
        description:
          'RYDE PASSをご利用いただきありがとうございます。<br/>お探しのページは移動・削除されたか、入力したURLに誤りがある可能性があります。',
      }
    case RydePassWebErrorType.Unknown:
      return {
        title: 'ページが表示できません',
        description:
          'ご不便をおかけし申し訳ございません。不明なエラーが発生いたしました。<br/>正常にご覧いただけるよう、解決に取り組んでおります。対応完了までしばらくお待ち下さい。',
      }
    default:
      return {
        title: 'ページが表示できません',
        description:
          'ご不便をおかけし申し訳ございません。不明なエラーが発生いたしました。<br/>正常にご覧いただけるよう、解決に取り組んでおります。対応完了までしばらくお待ち下さい。',
      }
  }
}

const ErrorTemplate: React.FC<Props> = ({
  type = RydePassWebErrorType.Unknown,
}: Props) => {
  const message = getMessage(type)
  const { title, description } = message

  return (
    <Wrapper>
      <Title padded>{title}</Title>
      <Text dangerouslySetInnerHTML={{ __html: description }} />
      <Centered>
        <Link href="/" passHref>
          <Button>TOPページへ</Button>
        </Link>
      </Centered>
    </Wrapper>
  )
}

ErrorTemplate.defaultProps = {
  type: RydePassWebErrorType.Unknown,
}

const Text = styled.p`
  ${theme.typography.Body1}
  text-align: center;
`

const Wrapper = styled.div`
  padding-top: 64px;
  padding-bottom: 64px;
  ${ContainerStyle}
`

const Centered = styled.div`
  margin-top: 60px;
  text-align: center;
`

export { ErrorTemplate }
