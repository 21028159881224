import { ApolloError } from '@apollo/client'

enum RydePassWebErrorType {
  NotFound = 'notFound',
  ServerError = 'serverError',
  NetworkError = 'networKError',
  Unknown = 'unknown',
}

const convertToErrorType = (apolloError: ApolloError): RydePassWebErrorType => {
  if (apolloError.networkError) {
    return RydePassWebErrorType.NetworkError
  }

  return RydePassWebErrorType.Unknown
}

export { convertToErrorType, RydePassWebErrorType }
